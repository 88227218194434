<template>
  <v-row justify="center" v-if="allergens">
    <v-dialog v-model="dialog" scrollable max-width="300px">
      <template v-slot:activator="{ on }">
        <!-- <v-btn color="primary" dark v-on="on">Open Dialog</v-btn> -->
        <v-btn
              absolute
              dark
              fab
              bottom
              right
              small
              v-on="on"
              @click.stop.prevent="dialog=true"
              :style="{ 'background-color': btnBackgroundColor}"
            >
              <v-icon v-if="1===1" :style="{ 'color': btnTextColor}">mdi-filter</v-icon>
              <v-icon v-else-if="enterpriseID !== 'iberostarseasuolseu' && enterpriseID !== '0772d01a-152a-4a22-9682-511ecba60207'" :style="{ 'color': btnTextColor}">mdi-comment-alert</v-icon>
              <!--v-img v-else :src="require('@/assets/aller-iberostar-icon.png')" /-->
              <v-icon v-else :style="{ 'color': btnTextColor}">mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-btn
          text
          x-small
          absolute
          top
          right
          :color="btnBackgroundColor"
          @click="dialog = false"
          style="margin-right: -15px !important;margin-top: 0px;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <v-card-title :style="{ 'color': btnTextColor}">{{ $t('dishes.allergenFilter') }}</v-card-title> -->
        <center :style="{ 'color': btnBackgroundColor}" style="font-size:20px;margin-top:30px;margin-bottom:15px;">{{ allergenFilterText }}</center>
        <center style="padding:0 40px;font-size:12px;margin-bottom:15px;">{{ selectAllergensText }}</center>
        <!-- <v-divider></v-divider> -->
        <v-card-text style="height: 300px;">
          <v-layout wrap style="padding: 5px">
                <v-flex
                  xs4
                  v-for="(allergen, index) in allergens"
                  :key="allergen.ID"
                  :style="{ 'border-left': '1px solid #CCC !important', 
                            'border-top': '1px solid #CCC !important', 
                            'border-right': (index+1) % 3 === 0 ? '1px solid #CCC !important' : '0',
                            'border-bottom': index >= (allergens.length -3) ? '1px solid #CCC !important' : '0'}"
                  @click="handleSelectAllergen(allergen.ID)"
                >
                  <table width="100%"
                    :style="{ 'background-color': selectedAllergenFilters.indexOf(allergen.ID) >= 0 ? '#CCCCCC' : 'white' }"
                  >
                    <tr>
                      <td style="padding: 0px;text-align:center;">
                        <img
                          :src="allergen.Image"
                          width="60%"
                          heigth="auto"
                          alt="item.Name"
                          style="margin: 5px 5px 0 5px;"
                        > 
                      </td>
                    </tr>    
                    <tr>
                      <td>
                        <center
                          :style="{ 'color': ColorContent,
                            'font-size': `${FontSizeContent - 11}px`,
                            'font-family': FontContent, 
                          }"
                        >
                          {{ allergen.Name.toUpperCase()}}
                        </center>
                      </td>
                    </tr>
                  </table>           
                </v-flex>
                <v-flex
                  xs4
                  :style="{ 'border-left': '1px solid #CCC !important' }"
                />
              </v-layout>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions>
           <v-spacer />          
          <v-btn text :color="btnTextColor" @click="dialog = false">{{ $t('common.close') }}</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  export default {
    props: {
    },
    data () {
      return {
        dialog: false,
        allergens: null,
        allergensData: null,
        selectedAllergenFilters: [],

        allergenFilterText: '',
        selectAllergensText: ''
      }
    },
    computed: {
      ...mapState('app',['locale', 'BGColor', 'FontContent', 'FontSizeContent', 'ColorContent']),
      enterpriseID () {
        return this.$store.state.app.enterpriseID ? this.$store.state.app.enterpriseID : utils.getDomain()
      },
      enterpriseColor () {
        return this.$store.state.app.enterpriseColor
      },
      btnBackgroundColor () {        
        return this.$store.state.app.btnBackgroundColor && this.$store.state.app.btnBackgroundColor !== '' ? this.$store.state.app.btnBackgroundColor : this.BGColor
      },
      btnTextColor () {
        return this.$store.state.app.btnTextColor && this.$store.state.app.btnTextColor !== '' ? this.$store.state.app.btnTextColor : 'white'
      },
      isDevelopment() {
        return process.env.VUE_APP_ENV === 'development'
      }
    },
    watch: {
      locale () {
        this.allergenFilterText = this.$t('dishes.allergenFilter', this.locale)
        this.selectAllergensText = this.$t('dishes.selectAllergens', this.locale)
        if (this.allergensData) this.convertAllergens(this.allergensData)
      }
    },
    mounted () {
      this.allergenFilterText = this.$t('dishes.allergenFilter', this.locale)
      this.selectAllergensText = this.$t('dishes.selectAllergens', this.locale)
      const enterpriseIDDomain = this.$store.state.app.enterpriseID ? this.$store.state.app.enterpriseID : utils.getDomain()
      api.getItem('utils', false, `allergens/${enterpriseIDDomain}`).then(response => {
        this.allergensData = response.data
        this.convertAllergens(response.data)
        // console.log(this.data.Allergens)
        // this.selectedAllergens = this.allergens.filter(x => this.data.FoodAllergens.indexOf(x.ID)>=0)
      })
    },
    methods: {
      convertAllergens (v) {
        this.allergens = JSON.parse(JSON.stringify(v)).map(item => {
          item.Name = utils.getTranslation (item.Name, this.locale)
          item.Image = `https://www.dtouch.es/assets/allergens/${item.Image}.png`
          return item
        })
      },
      handleSelectAllergen (id) {
        if (this.selectedAllergenFilters.indexOf(id) >= 0) {
          this.selectedAllergenFilters = this.selectedAllergenFilters.filter(x => x !== id)
        } else {
          this.selectedAllergenFilters.push(id)
        }        
        this.$store.state.app.selectedAllergenFilters = this.selectedAllergenFilters
      }
    }
  }
</script>

