var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.allergens)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({style:({ 'background-color': _vm.btnBackgroundColor}),attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.dialog=true}}},on),[(1===1)?_c('v-icon',{style:({ 'color': _vm.btnTextColor})},[_vm._v("mdi-filter")]):(_vm.enterpriseID !== 'iberostarseasuolseu' && _vm.enterpriseID !== '0772d01a-152a-4a22-9682-511ecba60207')?_c('v-icon',{style:({ 'color': _vm.btnTextColor})},[_vm._v("mdi-comment-alert")]):_c('v-icon',{style:({ 'color': _vm.btnTextColor})},[_vm._v("mdi-information-outline")])],1)]}}],null,false,1118701562),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-btn',{staticStyle:{"margin-right":"-15px !important","margin-top":"0px"},attrs:{"text":"","x-small":"","absolute":"","top":"","right":"","color":_vm.btnBackgroundColor},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('center',{staticStyle:{"font-size":"20px","margin-top":"30px","margin-bottom":"15px"},style:({ 'color': _vm.btnBackgroundColor})},[_vm._v(_vm._s(_vm.allergenFilterText))]),_c('center',{staticStyle:{"padding":"0 40px","font-size":"12px","margin-bottom":"15px"}},[_vm._v(_vm._s(_vm.selectAllergensText))]),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-layout',{staticStyle:{"padding":"5px"},attrs:{"wrap":""}},[_vm._l((_vm.allergens),function(allergen,index){return _c('v-flex',{key:allergen.ID,style:({ 'border-left': '1px solid #CCC !important', 
                          'border-top': '1px solid #CCC !important', 
                          'border-right': (index+1) % 3 === 0 ? '1px solid #CCC !important' : '0',
                          'border-bottom': index >= (_vm.allergens.length -3) ? '1px solid #CCC !important' : '0'}),attrs:{"xs4":""},on:{"click":function($event){return _vm.handleSelectAllergen(allergen.ID)}}},[_c('table',{style:({ 'background-color': _vm.selectedAllergenFilters.indexOf(allergen.ID) >= 0 ? '#CCCCCC' : 'white' }),attrs:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"padding":"0px","text-align":"center"}},[_c('img',{staticStyle:{"margin":"5px 5px 0 5px"},attrs:{"src":allergen.Image,"width":"60%","heigth":"auto","alt":"item.Name"}})])]),_c('tr',[_c('td',[_c('center',{style:({ 'color': _vm.ColorContent,
                          'font-size': ((_vm.FontSizeContent - 11) + "px"),
                          'font-family': _vm.FontContent, 
                        })},[_vm._v(" "+_vm._s(allergen.Name.toUpperCase())+" ")])],1)])])])}),_c('v-flex',{style:({ 'border-left': '1px solid #CCC !important' }),attrs:{"xs4":""}})],2)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }